/* #__next {
  position: fixed;
  z-index: -1;
  background: url(../public/assets/img/bg.png) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100vh;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.anim-height {
  animation-name: fromTopToBottom;
  animation-duration: 0.3s;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.fadeInLeft {
  animation-name: fadeInLeftAnim;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
.animate-delay-300 {
  animation-delay: 0.3s;
}
.animate-delay-400 {
  animation-delay: 0.4s;
}
.animate-delay-500 {
  animation-delay: 0.5s;
}
.animate-delay-600 {
  animation-delay: 0.6s;
}
.animate-delay-700 {
  animation-delay: 0.7s;
}
.animate-delay-800 {
  animation-delay: 0.8s;
}
.animate-delay-900 {
  animation-delay: 0.9s;
}
.animate-delay-1000 {
  animation-delay: 1s;
}
.animate-delay-1100 {
  animation-delay: 1.1s;
}
.animate-delay-1200 {
  animation-delay: 1.2s;
}
.animate-delay-1300 {
  animation-delay: 1.3s;
}

@keyframes fadeInLeftAnim {
  from {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fromTopToBottom {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
.btn-background {
  background: linear-gradient(
    180deg,
    rgba(79, 98, 104, 0.3) 0%,
    rgba(6, 12, 14, 0.3) 100%
  );
}

.filter-blur {
  filter: blur(3px);
}
.letter-color {
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #45dfac 55.95%,
    #23afa5 104.62%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.overflow-breakword {
  overflow-wrap: break-word;
}
.intoBackground {
  background-color: rgba(6, 12, 14, 0);
  background-image: url(../public/assets/img/Mask-group-51.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: contain;
}
.backgroundImage {
  background-image: url(../public/assets/img/viewpointBackground.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: contain;
  background-color: transparent;
}
.btnBackground:hover {
  background: linear-gradient(
    90.17deg,
    #38c8ed 2.67%,
    #45dfac 55.84%,
    #23afa5 105.79%
  );
}
.colorBtn {
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #3ffde0 55.95%,
    #23afa5 104.62%
  ) !important;
  border-radius: 33px;
  background-size: 200% auto !important;
  background-position: left center !important;
  transition: all 0.2s ease !important;
}

.colorBtn:hover {
  background-position: right center !important;
}

.AdBackground {
  background: linear-gradient(to bottom, #060c0e 27%, #071015 28.8%);
}

.TKBackground {
  background: linear-gradient(to bottom, #060c0e 48.4%, #071015 48.4%);
}
.WPBackground1::before {
  content: "";
  position: absolute;
  background: url(../public/assets/img/Group-1000001363.png);
  width: 641px;
  height: 536px;
  left: calc(50% + 20px);
  transform: translateX(-50%);
}
.WPBackground1 {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}
.WPBackground {
  background-color: transparent !important;
  background-image: url(../public/assets/img/Group-1000001710.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.borderImage {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, rgba(24, 37, 46), rgba(24, 37, 46, 0))
    1;
  position: relative;
}
.borderImage::before {
  content: "";
  position: absolute;
  height: 39px;
  width: 4px;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}
.firstBorder.borderImage::before {
  background: #45dfac;
}
.secondBorder.borderImage::before {
  background: #4edfff;
}
.thirdBorder.borderImage::before {
  background: #7c4eff;
}
.chainTable {
  border-collapse: unset !important;
  border: none !important;
  border-spacing: 0 !important;
  empty-cells: show;
  width: 100%;
  max-width: 100%;
  font-family: Poppins, sans-serif;
}

.chainTable thead tr th:not(:first-of-type) {
  background: #081721;
  border-right: 1px solid #18252e !important;
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: 1px solid #18252e !important;
  text-align: center !important;
}
.chainTable thead tr th:nth-child(2) {
  border-left: 1px solid #18252e !important;
}

.chainTable thead tr th:last-of-type {
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #3ffde0 55.95%,
    #23afa5 104.62%
  ) !important;
}

.chainTable thead tr th:last-of-type span {
  color: #081113 !important;
}

.chainTable tbody tr {
  background: #061118;
}
.chainTable tbody tr td:first-of-type div,
.chainTable tbody tr td:last-of-type {
  color: #fff !important;
}

.chainTable tbody tr td:first-of-type {
  text-align: left !important;
  padding-left: 30px !important;
  border-color: #18252e;
}

.chainTable tbody tr td {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-style: solid;
  border-width: 0;
  background-clip: border-box;
  border-right: 1px solid #18252e !important;
  border-bottom: 1px solid #18252e !important;
  color: #4d546c;
}
.chainTable tbody tr td:last-of-type {
  border-right: none !important;
}

@media screen and (min-width: 1280px) {
  .chainTable tbody tr td:first-of-type {
    font-size: 14px !important;
    line-height: 2em;
    padding: 18px 0px 18px 30px !important;
  }
  .chainTable tbody tr td {
    font-size: 14px !important;
  }
}

.font-Poppins {
  font-family: Poppins, sans-serif;
}

.font-Overpass {
  font-family: "Overpass", sans-serif;
}

.dot-border {
  background-color: rgba(124, 78, 255, 0.05);
  border-style: dashed;
  border-width: 0;
  background-clip: border-box;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.BorderColor1 {
  border-color: #7c4eff !important;
}
.BorderColor2 {
  border-color: #45dfac !important;
}
.BorderColor3 {
  border-color: #418ffa !important;
}
.BorderColor4 {
  border-color: #f2994a !important;
}
.BorderColor5 {
  border-color: #d62d79 !important;
}

.hodlorn-board {
  position: relative;
}
.hodlorn-board::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 115px;
  bottom: 0;
  left: 0;
}

.hodlorn-back1 .hodlorn-board::before {
  background: url(../public/assets/img/hodlorn-back1.png);
}
.hodlorn-back2 .hodlorn-board::before {
  background: url(../public/assets/img/hodlorn-back2.png);
}
.hodlorn-back3 .hodlorn-board::before {
  background: url(../public/assets/img/hodlorn-back3.png);
}
.hodlorn-back4 .hodlorn-board::before {
  background: url(../public/assets/img/hodlorn-back4.png);
}

.social-links {
  display: flex;
  flex-wrap: wrap;
}
.social-links a {
  border: 1px solid #232f32;
  margin: 6px 4px;
  border-radius: 50%;
  display: flex;
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(79, 98, 104, 0.3) 0%,
    rgba(6, 12, 14, 0.3) 100%
  );
  position: relative;
}
.social-links a:hover {
  color: #31c3a8;
}
.social-links a:hover::before {
  transform: scale(1);
}
.social-links a::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #3ffde0 55.95%,
    #23afa5 104.62%
  );
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.2s ease;
}

.social-links a img {
  position: relative;
  z-index: 9;
}

.footer-line {
  width: 100%;
  overflow: hidden !important;
  border-top: 1px solid #18252e;
}

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.icon {
  fill: var(--color-primary);
  width: 100%;
}

.btn {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2.5rem 1.125rem;
}
.btn:focus {
  outline-color: var(--color-focus);
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 3px;
}
.btn:active {
  transform: translateY(1px);
}

.slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
}
.slider__controls .btn {
  --size: 3rem;
  align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 100%;
  display: flex;
  height: var(--size);
  padding: 0;
  width: var(--size);
}
.slider__controls .btn:focus {
  border-color: var(--color-focus);
  outline: none;
}
.slider__controls .btn--previous > * {
  transform: rotate(180deg);
}
.slider {
  --slide-margin: 4vmin;
  height: var(--slide-size);
  margin: 0 auto;
  position: relative;
  width: var(--slide-size);
}
.advantage .slider {
  --slide-size: 350px;
}
.advantage2 .slider {
  --slide-size: 570px;
}
.feature-carousel .slider {
  --slide-size: 400px;
}
.slider__wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.slide {
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: var(--slide-size);
  justify-content: center;
  margin: 0 var(--slide-margin);
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease),
    transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
}
.slide--previous:hover,
.slide--next:hover {
  opacity: 0.5;
}
.slide--previous {
  cursor: w-resize;
}
.slide--previous:hover {
  transform: translateX(2%);
}
.slide--next {
  cursor: e-resize;
}
.slide--next:hover {
  transform: translateX(-2%);
}

.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
@media (hover: hover) {
  .slide--current:hover .slide__image-wrapper {
    transform: scale(1.025)
      translate(
        calc(var(--x) / var(--d) * 1px),
        calc(var(--y) / var(--d) * 1px)
      );
  }
}

.slide__image-wrapper {
  background-color: var(--color-accent);
  border-radius: 1%;
  height: 100%;
  left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
}

.slide__image {
  --d: 20;
  height: 100%;
  left: -5%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition: opacity var(--base-duration) var(--base-ease),
    transform var(--base-duration) var(--base-ease);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 110%;
}
/* @media (hover: hover) {
  .slide--current .slide__image {
    transform: translate(
      calc(var(--x) / var(--d) * 1px),
      calc(var(--y) / var(--d) * 1px)
    );
  }
} */

.slide__headline {
  font-size: 8vmin;
  font-weight: 600;
  position: relative;
}

.slide__content {
  --d: 60;
  opacity: 0;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;
}
.slide--current .slide__content {
  -webkit-animation: fade-in calc(var(--base-duration) / 2) var(--base-ease)
    forwards;
  animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
  visibility: visible;
}
@media (hover: hover) {
  .slide--current .slide__content {
    transform: translate(
      calc(var(--x) / var(--d) * -1px),
      calc(var(--y) / var(--d) * -1px)
    );
  }
}
.slide__content > * + * {
  margin-top: 2rem;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.roadmap-wrapper {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  padding-top: 94px;
  min-height: 100vh;
  overflow: hidden;
}
.roadmap-wrapper::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    266.83deg,
    #060c0e 2.98%,
    rgba(6, 12, 14, 0) 97.77%
  );
  width: 9.8vw;
  height: 100%;
  z-index: 9999999;
  right: 0;
}

.roadmap-text {
  text-align: center;
  position: relative;
  z-index: 9999;
}
.roadmap-slide-show {
  position: relative;
  padding: 250px 0 164px !important;
  height: 575px;
}
.roadmap-year {
  position: absolute;
  width: 23.6vw;
  height: 23.6vw;
  top: 50%;
  transform: translateY(-50%);
  left: calc((100vw - 1150px) / 2);
  z-index: 99;
}
.roadmap-year::before,
.roadmap-year::after {
  content: "";
  position: absolute;
  border: 1px solid #18252e;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #060c0e;
}
.roadmap-year::before {
  width: calc(100% + 7.2vw);
  height: calc(100% + 7.2vw);
}
.roadmap-year::after {
  width: calc(100% + 15.6vw);
  height: calc(100% + 15.6vw);
  opacity: 0.4;
}
.roadmap-wrapper-slide-parent {
  overflow: hidden;
}
.roadmap-year-content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-image: url(../public/assets/img/Ellipse-68-Stroke.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  width: 23.6vw;
  height: 23.6vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 93px;
  padding-top: 20px;
  background-color: #060c0e;
  border-radius: 50%;
  z-index: 999;
}
.roadmap-year-content::before {
  content: "";
  position: absolute;
  width: 100vw;
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #3ffde0 55.95%,
    #23afa5 104.62%
  );
  height: 1px;
  left: 100%;
  border-radius: 33px;
  top: 50%;
  transform: translateY(-50%);
}

.linear-heading .fl-heading span {
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #45dfac 55.95%,
    #23afa5 104.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 99;
}
.roadmap-wrapper-slide {
  position: absolute;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  left: calc(((100vw - 1150px) / 2) + 455px);
  overflow: hidden;
  justify-content: center;
}
.roadmap-wrapper-top {
  padding-left: 4vw;
}
.roadmap-wrapper-bottom {
  padding-left: 20vw;
}
.roadmap-wrapper-bottom,
.roadmap-wrapper-top,
.roadmap-wrapper-slide {
  display: flex;
  align-items: center;
}
.roadmap-wrapper-slide .separator {
  margin: 69px 0;
  opacity: 0;
}
.roadmap-box {
  display: flex;
  margin-right: 10vw;
  padding-left: 90px;
  border: 1px solid #18252e;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.roadmap-box::before {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  background: red;
  bottom: -17px;
  left: -17px;
  transform: rotate(44.4deg);
  border-top: 1px solid #18252e;
  background: #060c0e;
}
.roadmap-content-box {
  padding-right: 40px;
  text-align: right;
  white-space: nowrap;
}
.roadmap-row .fl-html {
  height: 3000px;
}
.polygonItem-content {
  position: relative;
}

.polygonItem-content::before {
  content: "";
  position: absolute;
  /* background: radial-gradient(50% 50% at 50% 50%, rgba(14, 245, 183, 0.4) 0%, rgba(0, 217, 158, 0) 100%); */
  filter: blur(110px);
  width: 18.1vw;
  height: 18.1vw;
  background-size: 100% 100%;
  bottom: -70px;
  right: -65px;
}

.stakingItem2 {
  position: relative;
  display: inline-block;
}

.stakingItem2::before {
  position: absolute;
  content: "";
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(14, 245, 183, 0.4) 0%,
    rgba(0, 217, 158, 0) 100%
  );
  filter: blur(110px);
  width: 24.4vw;
  height: 24.4vw;
  background-size: 100% 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
}

.benefit-item {
  position: relative;
}

.benefit-item::before {
  content: "";
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(14, 245, 183, 0.4) 0%,
    rgba(0, 217, 158, 0) 100%
  );
  filter: blur(50px);
  width: 167px;
  height: 167px;
  top: 45px;
  left: 20px;
}
.staking-board {
  border: 10px solid transparent;
  border-image: url(../public/assets/img/Rectangle-99.svg) 10 stretch;
}
.staking-access-board {
  background-image: url(../public/assets/img/Mask-group-50.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.contact-board {
  background-color: #060c0e;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: scroll;
  background-size: auto;
  background-blend-mode: color-dodge !important;
}

.contact-board::before {
  content: "";
  position: absolute;
  background: url(../public/assets/img/Group-1000001732.png);
  width: 1328px;
  height: 790px;
  left: calc(50% + 50px);
  bottom: -40px;
  transform: translateX(-50%);
  z-index: 9;
}
.adrestus-color {
  background: linear-gradient(
    90.24deg,
    #38c8ed 4.13%,
    #45dfac 55.95%,
    #23afa5 104.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: relative;
  z-index: 99;
}
.contact-parent {
  padding-left: 24px;
  position: relative;
  display: block;
}
.contact-parent::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    175.59deg,
    #38c8ed 14.62%,
    #45dfac 46.34%,
    #23afa5 76.14%
  );
  border-radius: 2px;
  width: 4px;
  height: 286px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.contact-input {
  background: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  font-size: 14px !important;
  color: #4d546c !important;
  border-radius: 0 !important;
  padding-bottom: 24px;
  border-bottom: 1px solid #18252e !important;
  padding-top: 0 !important;
  transition: all ease-in-out 0.15s;
  vertical-align: middle;
  line-height: 1.428571429;
  display: block;
  width: 100%;
  padding: 10px 12px;
}

.contact-input:focus {
  outline: none;
}
.mini-table-header {
  display: flex !important;
  flex-direction: row !important;
}
/* Media Query*/

@media screen and (max-width: 767px) {
  .advantage2 .slider img {
    width: 228px !important;
  }
  .social-links a {
    width: 32px;
    height: 32px;
    margin: 0 3px !important;
  }
  .hodlorn-board::before {
    height: 142px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .WPBackground1::before {
    width: 409px;
    height: 342px;
    left: calc(50%);
    background-size: cover;
  }
  .roadmap-wrapper {
    min-height: 100vh;
    padding-top: 40px;
  }
  .roadmap-wrapper::before {
    width: 20px;
  }
  .roadmap-year {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: 25px;
  }
  .roadmap-year-content::before {
    content: "";
    width: 0;
  }
  .roadmap-wrapper-slide {
    left: 20px;
    top: 375px;
    transform: none;
  }
  .roadmap-wrapper-top {
    padding-left: 0;
  }
  .roadmap-wrapper-slide .separator {
    margin: 12px 0 !important;
  }
  .roadmap-slide-show {
    padding-top: 20px;
  }
  .polygonItem-content::before {
    width: 258px;
    height: 258px;
    bottom: -20px;
    right: -15px;
  }
  .polygonItem::before {
    background-size: 100% auto !important;
    width: 0px !important;
    height: 0px !important;
    top: 70px !important;
    right: -15px !important;
    background-repeat: no-repeat !important;
    background-position: right top !important;
  }
  .stakingItem2::before {
    width: 308px !important;
    height: 308px !important;
  }
  .contact-board::before {
    background-size: 265% auto !important;
    background-position: center !important;
    left: 0 !important;
    bottom: 90px !important;
    width: 100% !important;
    transform: none !important;
    background-repeat: no-repeat !important;
  }
  .contact-parent {
    padding-left: 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .contact-parent::before {
    display: none !important;
  }
  .contact-info {
    position: relative;
  }
  .contact-info::before {
    background: linear-gradient(
      175.59deg,
      #38c8ed 14.62%,
      #45dfac 46.34%,
      #23afa5 76.14%
    );
    height: 106px;
    width: 4px;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .contact-info-2 {
    position: relative;
  }
  .contact-info-2::before {
    background: linear-gradient(
      175.59deg,
      #38c8ed 14.62%,
      #45dfac 46.34%,
      #23afa5 76.14%
    );
    height: 106px;
    width: 4px;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .roadmap-row .fl-html {
    height: 1500px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .social-links a {
    width: 24px;
    height: 24px;
    margin: 4px 2px !important;
  }
  .roadmap-wrapper {
    min-height: 60vh;
  }
  .roadmap-wrapper::before {
    width: 20px;
  }
  .roadmap-slide-show {
    padding: 200px 0 164px !important;
    height: 430px;
  }
  .roadmap-year {
    left: 60px;
  }
  .polygonItem::before {
    background-size: 100% auto !important;
    width: 111px !important;
    height: 190px !important;
    top: 70px !important;
    right: -15px !important;
    background-repeat: no-repeat !important;
    background-position: right top !important;
  }
  .contact-board::before {
    background-size: 115% auto !important;
    background-repeat: no-repeat !important;
    background-position: left center !important;
    left: 0px !important;
    transform: none !important;
    bottom: -13px !important;
    width: 100% !important;
  }
  .contact-parent::before {
    height: 234px !important;
  }
  .roadmap-row .fl-html {
    height: 1200px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .social-links a {
    width: 33px;
    height: 33px;
  }
  .roadmap-year {
    left: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .hodlorn-board::before {
    height: 80px;
  }
  .chainTable tbody tr td:first-of-type {
    font-size: 9px !important;
    padding: 12px 0 !important;
    padding-left: 17px !important;
  }
  .chainTable tbody tr td {
    font-size: 9px !important;
    padding: 12px 0 !important;
  }

  .roadmap-year {
    width: 250px;
    height: 250px;
  }
  .roadmap-year::before {
    width: calc(100% + 70px);
    height: calc(100% + 70px);
  }
  .roadmap-year::after {
    width: calc(100% + 150px);
    height: calc(100% + 150px);
  }
  .roadmap-year-content {
    padding-left: 50px;
    width: 250px;
    height: 250px;
  }

  .roadmap-wrapper-top {
    padding-left: 15vw;
  }
  .roadmap-box {
    padding-left: 50px;
    margin-right: 7vw;
  }
  .roadmap-content-box {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .chainTable tbody tr td:first-of-type {
    font-size: 10px !important;
    line-height: 2em;
    padding: 18px 0px 18px 30px !important;
  }
  .chainTable tbody tr td {
    font-size: 12px !important;
  }
  .polygonItem::before {
    background-size: 50% auto !important;
    background-repeat: no-repeat !important;
    background-position: right top !important;
    top: 110px !important;
    right: -25px !important;
  }
}
@media screen and (max-width: 1200px) {
  .roadmap-year-content {
    padding-left: 60px;
  }
  .roadmap-wrapper-slide .separator {
    margin: 50px 0;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1300px) {
  .polygonItem::before {
    background-size: 80% auto !important;
    background-repeat: no-repeat !important;
    background-position: right top !important;
  }
}
@media screen and (max-width: 1400px) {
  .roadmap-year-content {
    padding-left: 70px;
  }
  .roadmap-wrapper-slide {
    align-items: flex-start !important;
  }
}
@media screen and (max-width: 570px) {
  .mini-table-header {
    display: flex !important;
    flex-direction: column !important;
  }
}
.staking-panel {
  background-image: url(../public/assets/img/Group-1000001707.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  background-color: transparent !important;
}
.staking-panel::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(18, 45, 51, 0.87) 0%,
    rgba(8, 25, 29, 0.87) 47.52%,
    rgba(6, 12, 14, 0.87) 100%
  );
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.polygonItem {
  position: relative;
}

.polygonItem::before {
  content: "";
  position: absolute;
  background: url(../public/assets/img/Group-1000001704.png);
  width: 218px;
  height: 303px;
  top: 85px;
  right: 0px;
}
